import React, { useEffect } from "react";
import classes from './BestConverting.module.scss';
import { useTranslation } from "react-i18next";
import palm from '../../../assets/img/HomePage/BestConverting/palm.png';
import light from '../../../assets/img/HomePage/BestConverting/light.png';
import light_red from '../../../assets/img/Footer/light.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const cards = [
    {
        title: 'home_best_converting_card_01_title',
        list: [
            'home_best_converting_card_01_li_01',
            'home_best_converting_card_01_li_02',
            'home_best_converting_card_01_li_03',
        ],
    },
    {
        title: 'home_best_converting_card_02_title',
        list: [
            'home_best_converting_card_02_li_01',
            'home_best_converting_card_02_li_02',
            'home_best_converting_card_02_li_03',
            'home_best_converting_card_02_li_04',
        ],
    },
];

const BestConverting = () => {
    const { t } = useTranslation();
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            setTimeout(() => {
                gsap.fromTo('#bestConvPalmRef', {
                    scale: 0.95,
                    y: 30,
                }, {
                    scale: 1,
                    y: 0,
                    duration: 1,
                    scrollTrigger: {
                        trigger: "#bestConvBodyRef",
                        start: "center bottom",
                    }
                });
            }, 300);
        }
    }, []);
    return (
        <section className={classes.bestConvertingWrap}>
            <div className="container">
                <div id="bestConvBodyRef" className={classes.bestConverting}>
                    <img id="bestConvPalmRef" className={classes.palm} src={palm} alt=''/>
                    <img className={classes.light} src={light} alt=''/>
                    <img className={classes.lightRed} src={light_red} alt=''/>
                    <div className={classes.cardsWrap}>
                        <div className={classes.cards}>
                            {cards.map((card, index) =>
                                <div key={index} className={classes.cardWrap}>
                                    <div className={classes.card}>
                                        <h4 className={[classes.cardTitle, 'font-18-mont'].join(' ')}>
                                            {t(card.title)}
                                        </h4>
                                        <ul className={[classes.cardList, 'font-17'].join(' ')}>
                                            {card.list.map((li, liIndex) =>
                                                <li key={liIndex}>
                                                    {t(li)}
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BestConverting;
