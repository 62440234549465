import React from "react";
import classes from "./Footer.module.scss";
import logo from "../../../assets/img/logo.png";
import { Trans, useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import car from '../../../assets/img/Footer/car.png';
import light from '../../../assets/img/Footer/light.png';
import smoke from '../../../assets/img/Footer/smoke.png';

const Footer = () => {
  const { t } = useTranslation();
  const { lang } = useParams();
  return (
    <footer className={classes.footerWrap}>
      <div className={classes.smokeWrap}>
        <img className={classes.smoke} src={smoke} alt="smoke"/>
      </div>
      <div className={classes.smokeWrap}>
        <img className={classes.smoke2} src={smoke} alt="smoke"/>
      </div>
      <div className={classes.smokeWrap}>
        <img className={classes.smoke3} src={smoke} alt="smoke"/>
      </div>
      <img className={classes.car} src={car} alt=''/>
      <img className={classes.light} src={light} alt=''/>
      <div className="container">
        <div className={classes.footer}>
          <div className={classes.logo}>
            <img className={classes.logoImg} src={logo} alt='plm'/>
          </div>
          <div className={classes.col}>
            <p className={classes.address}>
              Envyus Media5135 W Central AveWichita, KS 67212
            </p>
          </div>
          <div className={classes.col}>
            <div className={classes.docs}>
              <a href="/docs/PRIVACY_POLICY.pdf" target="_blank" className="cursorHoverEl">
                {t('footer_privacy_policy')}
              </a>
              <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank" className="cursorHoverEl">
                {t('footer_terms_conditions')}
              </a>
              <a href="/docs/CLIENT_AGREEMENT.pdf" target="_blank" className="cursorHoverEl">
                {t('footer_client_agreement')}
              </a>
            </div>
            <p className={classes.rights}>
              {t('footer_rights')}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
