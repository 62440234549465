import HomePage from '../pages/HomePage/HomePage';

export const routes = [
    { path: '/', element: HomePage },
    { path: '/:lang/', element: HomePage },
];

export const headerRoutes = [
    // { id: 1, path: 'conditions', text: 'Trading Conditions' },
];
