import React, { useEffect } from "react";
import classes from './Values.module.scss';
import { useTranslation } from "react-i18next";
import title_en from '../../../assets/img/HomePage/Values/title_en.png';
import title_ua from '../../../assets/img/HomePage/Values/title_ua.png';
import house from '../../../assets/img/HomePage/Values/house.png';
import light from '../../../assets/img/HomePage/Values/light.png';
import light_red from '../../../assets/img/Footer/light.png';
import Cookies from 'js-cookie';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const Values = () => {
    const { t } = useTranslation();
    const lang = Cookies.get('i18next');
    const cards = [
        {
            title: 'home_values_card_01_title',
            text: 'home_values_card_01_text',
        },
        {
            title: 'home_values_card_02_title',
            text: 'home_values_card_02_text',
        },
        {
            title: 'home_values_card_03_title',
            text: 'home_values_card_03_text',
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            setTimeout(() => {
                gsap.fromTo('#valuesHouseRef', {
                    scale: 0.95,
                    y: 20,
                }, {
                    scale: 1,
                    y: 0,
                    duration: 1,
                    scrollTrigger: {
                        trigger: '#valuesBodyRef',
                        start: "bottom bottom",
                    }
                });
                gsap.fromTo('#valuesCardTitleRef_01', {
                    x: 50,
                }, {
                    x: 0,
                    duration: 1,
                    scrollTrigger: {
                        trigger: '#valuesBodyRef',
                        start: "bottom bottom",
                    }
                });
                gsap.fromTo('#valuesCardTextRef_01', {
                    x: 30,
                }, {
                    x: 0,
                    duration: 1,
                    scrollTrigger: {
                        trigger: '#valuesBodyRef',
                        start: "bottom bottom",
                    }
                });
                gsap.fromTo('#valuesCardTitleRef_02', {
                    x: 50,
                }, {
                    x: 0,
                    duration: 1,
                    scrollTrigger: {
                        trigger: '#valuesBodyRef',
                        start: "bottom bottom",
                    }
                });
                gsap.fromTo('#valuesCardTextRef_02', {
                    x: 30,
                }, {
                    x: 0,
                    duration: 1,
                    scrollTrigger: {
                        trigger: '#valuesBodyRef',
                        start: "bottom bottom",
                    }
                });
                gsap.fromTo('#valuesCardTitleRef_03', {
                    x: -50,
                }, {
                    x: 0,
                    duration: 1,
                    scrollTrigger: {
                        trigger: '#valuesBodyRef',
                        start: "bottom bottom",
                    }
                });
                gsap.fromTo('#valuesCardTextRef_03', {
                    x: -30,
                }, {
                    x: 0,
                    duration: 1,
                    scrollTrigger: {
                        trigger: '#valuesBodyRef',
                        start: "bottom bottom",
                    }
                });
            }, 300);
        }
    }, []);
    return (
        <section className={classes.valuesWrap}>
            <div className="container">
                <div id="valuesBodyRef" className={classes.values}>
                    <div className={classes.title}>
                        <img 
                            src={lang === 'en' ? title_en : title_ua} 
                            className={`${lang === 'en' ? classes.titleEn : classes.titleUa}`}
                        />
                    </div>
                    <div className={classes.cards}>
                        {cards.map((card, index) =>
                            <div key={index} className={classes.card}>
                                <h4 id={`valuesCardTitleRef_0${index+1}`} className={classes.cardTitle}>
                                    {t(card.title)}
                                </h4>
                                <p id={`valuesCardTextRef_0${index+1}`} className={classes.cardText}>
                                    {t(card.text)}
                                </p>
                            </div>
                        )}
                    </div>
                    <div id="valuesHouseRef" className={classes.house}>
                        <img src={house} alt=''/>
                    </div>
                    <img className={classes.light} src={light} alt=''/>
                    <img className={classes.lightRed} src={light_red} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default Values;
