import React from "react";
import classes from "./Button.module.scss";
import Cookies from 'js-cookie';

const Button = ({children, setFormActive, setFormFrom, formFrom}) => {
  const lang = Cookies.get('i18next');
  const openForm = () => {
    setFormActive(true);
    setFormFrom(formFrom)
  };
  return (
    <div 
      onClick={openForm}
      className={`${classes.button} cursorHoverEl`}
    >
      <span>{children}</span>
    </div>
  );
};

export default Button;
