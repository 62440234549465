import logo from '../assets/img/logo.png';
import banner_01 from '../assets/img/HomePage/Banner/build_01.png';
import banner_02 from '../assets/img/HomePage/Banner/build_02.png';
import banner_03 from '../assets/img/HomePage/Banner/car_01.png';
import banner_04 from '../assets/img/HomePage/Banner/car_02.png';
import banner_05 from '../assets/img/HomePage/Banner/car_mob_01.png';
import banner_06 from '../assets/img/HomePage/Banner/car_mob_02.png';
import banner_07 from '../assets/img/HomePage/Banner/girl_01.png';
import banner_08 from '../assets/img/HomePage/Banner/girl_02.png';
import banner_09 from '../assets/img/HomePage/Banner/ground.png';
import banner_10 from '../assets/img/HomePage/Banner/palm_01.png';
import banner_11 from '../assets/img/HomePage/Banner/palm_02.png';
import banner_12 from '../assets/img/HomePage/Banner/wheel.png';

export const images = [
    logo,
    banner_01,
    banner_02,
    banner_03,
    banner_04,
    banner_05,
    banner_06,
    banner_07,
    banner_08,
    banner_09,
    banner_10,
    banner_11,
    banner_12,
];
