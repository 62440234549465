import React, { useState } from "react";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/HomePage/Banner/Banner";
import Advantages from "../../components/HomePage/Advantages/Advantages";
import Offers from "../../components/HomePage/Offers/Offers";
import HourSupport from "../../components/HomePage/HourSupport/HourSupport";
import Geo from "../../components/HomePage/Geo/Geo";
import Values from "../../components/HomePage/Values/Values";
import BestConverting from "../../components/HomePage/BestConverting/BestConverting";
import Form from "../../components/GlobalComponents/Form/Form";

const HomePage = () => {
  const [ formActive, setFormActive ] = useState(false);
  const [ formFrom, setFormFrom ] = useState('');
  return (
    <div>
      <Form formActive={formActive} setFormActive={setFormActive} formFrom={formFrom} setFormFrom={setFormFrom} />
      <Header />
      <Banner setFormActive={setFormActive} setFormFrom={setFormFrom} />
      <Offers setFormActive={setFormActive} setFormFrom={setFormFrom} />
      <HourSupport setFormActive={setFormActive} setFormFrom={setFormFrom} />
      <Geo setFormActive={setFormActive} setFormFrom={setFormFrom} />
      <Values />
      <BestConverting />
      <Advantages />
      <Footer />
    </div>
  );
};

export default HomePage;
