import React, { useEffect, useRef } from "react";
import classes from './Advantages.module.scss';
import { useTranslation } from "react-i18next";
import title_en from '../../../assets/img/HomePage/Advantages/title_01.png';
import title_ua from '../../../assets/img/HomePage/Advantages/title_02.png';
import Cookies from 'js-cookie';
import { gsap } from "gsap";
gsap.config({
  force3D: true,
});

const Advantages = () => {
    const { t } = useTranslation();
    const lang = Cookies.get('i18next');
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cardRef_04 = useRef(null);
    const cards = [
        {
            ref: cardRef_01,
            title: 'home_advantages_card_01_title',
            text: 'home_advantages_card_01_text',
        },
        {
            ref: cardRef_02,
            title: 'home_advantages_card_02_title',
            text: 'home_advantages_card_02_text',
        },
        {
            ref: cardRef_03,
            title: 'home_advantages_card_03_title',
            text: 'home_advantages_card_03_text',
        },
        {
            ref: cardRef_04,
            title: 'home_advantages_card_04_title',
            text: 'home_advantages_card_04_text',
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            setTimeout(() => {
                gsap.fromTo(cardRef_01.current, {
                    x: -100,
                }, {
                    x: 0,
                    duration: 1,
                    scrollTrigger: {
                        trigger: cardRef_01.current,
                    }
                });
                gsap.fromTo(cardRef_02.current, {
                    x: 100,
                }, {
                    x: 0,
                    duration: 1,
                    scrollTrigger: {
                        trigger: cardRef_01.current,
                    }
                });
                gsap.fromTo(cardRef_03.current, {
                    x: -100,
                }, {
                    x: 0,
                    duration: 1,
                    scrollTrigger: {
                        trigger: cardRef_03.current,
                    }
                });
                gsap.fromTo(cardRef_04.current, {
                    x: 100,
                }, {
                    x: 0,
                    duration: 1,
                    scrollTrigger: {
                        trigger: cardRef_03.current,
                    }
                });
            }, 300);
        }
    }, []);
    return (
        <section className="container">
            <div className={classes.advantages}>
                <div className={classes.titleMob}>
                    <img 
                        src={lang === 'en' ? title_en : title_ua} 
                        className={`${lang === 'en' ? classes.titleEn : classes.titleUa}`}
                    />
                </div>
                <div className={classes.cards}>
                    <div className={classes.title}>
                        <img 
                            src={lang === 'en' ? title_en : title_ua} 
                            className={`${lang === 'en' ? classes.titleEn : classes.titleUa}`}
                        />
                    </div>
                    {cards.map((card, index) =>
                        <div ref={card.ref} key={index} className={classes.cardWrap}>
                            <div className={`${classes.card} ${lang === 'ua' && classes.cardUa}`}>
                                <h4 className={[classes.cardTitle, 'font-18-mont'].join(' ')}>
                                    {t(card.title)}
                                </h4>
                                <p className={[classes.cardText, 'font-17'].join(' ')}>
                                    {t(card.text)}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Advantages;
