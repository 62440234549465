import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { images } from "./utils/imagesToLoad";
import Router from './components/GlobalComponents/Router/AppRouter';
import Loader from './components/GlobalComponents/Loader/Loader';
import $ from 'jquery';
import './App.scss';
import Stars from "./components/GlobalComponents/Stars/Stars";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const loadImage = (image) => {
    return new Promise((resolve, reject) => {
      const newImage = new Image();
      newImage.src = image;
      newImage.onload = () => {
        resolve(image);
      }
      newImage.onerror = (err) => reject(err);
    });
  };
  useEffect(() => {
    if (isLoading) {
      $('body').addClass("ovf-hidden");
    }
    Promise
      .all(images.map((image) => loadImage(image)))
      .then(() => setIsLoading(false))
      .catch((err) => console.log("Failed to load images", err));
  }, []);
  useEffect(() => {
    if (!isLoading) {
      $('body').removeClass("ovf-hidden");
    }
  }, [isLoading]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateProperties = (elem, state) => {
    elem.style.setProperty('--x', `${ state.x }px`);
    elem.style.setProperty('--y', `${ state.y }px`);
    elem.style.setProperty('--width', `${ state.width }px`);
    elem.style.setProperty('--height', `${ state.height }px`);
    elem.style.setProperty('--radius', state.radius);
    elem.style.setProperty('--scale', state.scale);
  };
  $(document).ready(function(){
    document.querySelectorAll('.cursor').forEach((cursor) => {
      let onElement;
      const createState = (e) => {
        const defaultState = {
          x: e.clientX,
          y: e.clientY,
          width: 42,
          height: 42,
          radius: '100px',
        };
        const computedState = {};
        if (onElement != null) {
          const { top, left, width, height } = onElement.getBoundingClientRect();
          const radius = window.getComputedStyle(onElement).borderTopLeftRadius;
          
          computedState.x = left + width / 2;
          computedState.y = top + height / 2;
          computedState.width = width;
          computedState.height = height;
          computedState.radius = radius;
        }
        return {
          ...defaultState,
          ...computedState,
        };
      }
      document.addEventListener('mousemove', (e) => {
        const state = createState(e);
        updateProperties(cursor, state);
      });
      document.querySelectorAll('.cursorHoverEl').forEach((elem) => {
        elem.addEventListener('mouseenter', () => onElement = elem);
        elem.addEventListener('mouseleave', () => onElement = undefined);
      });
    });
  });

  return (
    <>
      <Loader isLoading={isLoading} />
      <HelmetProvider>
        {!isLoading &&
          <BrowserRouter>
            <div className="cursor"></div>
            <Stars />
            <div className="ovf-hidden">
              <Router />
            </div>
          </BrowserRouter>
        }
      </HelmetProvider>
    </>
  );
}

export default App;
