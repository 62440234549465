import React from "react";
import classes from './Stars.module.scss';

const Stars = () => {
    return (
        <div className={classes.stars}>
            <div className={classes.stars}></div>
            <div className={classes.stars2}></div>
            <div className={classes.stars3}></div>
        </div>
    );
};

export default Stars;
