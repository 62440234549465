import React, { useEffect, useRef } from "react";
import classes from '../Offers/Offers.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/HomePage/Blocks/image_03.png';
import telegram from '../../../assets/img/HomePage/Blocks/telegram.png';
import palm from '../../../assets/img/HomePage/Blocks/palm_geo.png';
import light from '../../../assets/img/Footer/light.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const Geo = ({ setFormActive, setFormFrom }) => {
    const { t } = useTranslation();
    const lineRef = useRef(null);
    const bodyRef = useRef(null);
    const imageRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            setTimeout(() => {
                gsap.fromTo(imageRef.current, {
                    scale: 0.96,
                    x: 10,
                    y: -10,
                }, {
                    scale: 1,
                    x: 0,
                    y: 0,
                    duration: 1,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        start: "bottom bottom",
                    }
                });
                gsap.fromTo(lineRef.current, {
                    x: -100,
                }, {
                    x: 0,
                    duration: 1,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        start: "bottom bottom",
                    }
                });
            }, []);
        }
    }, []);
    const openForm = () => {
      setFormActive(true);
      setFormFrom('Contact Us')
    };
    return (
        <section className={classes.blockWrap}>
            <img className={classes.palm_geo} src={palm} alt=''/>
            <div className="container">
                <div ref={bodyRef} className={[classes.block, classes.block_03].join(' ')}>
                    <div className={classes.content}>
                        <p className={[classes.num, 'font-25'].join(' ')}>
                            50+
                        </p>
                        <h4 className={[classes.title, 'font-45'].join(' ')}>
                            {t('home_geo_title')}
                        </h4>
                        <p className={[classes.text, 'font-20'].join(' ')}>
                            <Trans>
                                {t('home_geo_text')}
                            </Trans>
                        </p>
                        <div ref={lineRef} className={classes.line}></div>
                        <div 
                            onClick={openForm} 
                            className={[classes.button, 'font-20 no-select cursorHoverEl'].join(' ')}
                        >
                            <span className={classes.buttonBody}>
                                <p className={classes.buttonBodyText}>
                                    {t('btn_contact_us')}
                                </p>
                                <img src={telegram} alt=''/>
                            </span>
                        </div>
                    </div>
                    <img ref={imageRef} className={[classes.image, classes.image_03].join(' ')} src={image} alt=''/>
                    <img className={classes.light} src={light} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default Geo;
