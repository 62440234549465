import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import car_01 from '../../../assets/img/HomePage/Banner/car_01.png';
import car_02 from '../../../assets/img/HomePage/Banner/car_02.png';
import car_mob_01 from '../../../assets/img/HomePage/Banner/car_mob_01.png';
import car_mob_02 from '../../../assets/img/HomePage/Banner/car_mob_02.png';
import wheel from '../../../assets/img/HomePage/Banner/wheel.png';
import girl_01 from '../../../assets/img/HomePage/Banner/girl_01.png';
import girl_02 from '../../../assets/img/HomePage/Banner/girl_02.png';
import palm_01 from '../../../assets/img/HomePage/Banner/palm_01.png';
import palm_02 from '../../../assets/img/HomePage/Banner/palm_02.png';
import build_01 from '../../../assets/img/HomePage/Banner/build_01.png';
import build_02 from '../../../assets/img/HomePage/Banner/build_02.png';
import Button from '../../../components/GlobalComponents/Button/Button';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const Banner = ({ setFormActive, setFormFrom }) => {
    const { t } = useTranslation();
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.to("#carWrapRef_01", {
                x: 0,
                duration: 3,
            })
            gsap.to("#carWrapRef_02", {
                x: 0,
                duration: 3,
            })
            gsap.to('.wheelWrapRef_01', {
                rotate: 650,
                duration: 3,
            });
            gsap.to('.wheelWrapRef_02', {
                rotate: -450,
                duration: 3,
            });
            gsap.to('#girlWrapRef_01', {
                y: 0,
                x: 0,
                scale: 1,
                duration: 3,
            });
            gsap.to('#girlWrapRef_02', {
                y: 0,
                x: 0,
                scale: 1,
                duration: 3,
            });
            gsap.to('#buildWrapRef_01', {
                y: 0,
                x: 0,
                scale: 1,
                duration: 3,
            });
            gsap.to('#buildWrapRef_02', {
                y: 0,
                x: 0,
                scale: 1,
                duration: 3,
            });
            
            gsap.timeline().to('#buildRef_01', {
                x: -30,
                y: -10,
                scale: 1.05,
                scrollTrigger: {
                    trigger: '#bodyRef',
                    start: "+=300px",
                    end: "bottom top",
                    scrub: 4,
                }
            });
            gsap.timeline().to('#buildRef_02', {
                x: 20,
                y: -20,
                scale: 1.05,
                scrollTrigger: {
                    trigger: '#bodyRef',
                    start: "+=300px",
                    end: "bottom top",
                    scrub: 4,
                }
            }); 
            gsap.timeline().to('#girlRef_01', {
                x: 30,
                y: 15,
                scale: 0.95,
                scrollTrigger: {
                    trigger: '#bodyRef',
                    start: "+=300px",
                    end: "bottom top",
                    scrub: 4,
                }
            });
            gsap.timeline().to('#girlRef_02', {
                x: -30,
                y: 15,
                scale: 0.95,
                scrollTrigger: {
                    trigger: '#bodyRef',
                    start: "+=300px",
                    end: "bottom top",
                    scrub: 4,
                }
            });
            gsap.timeline().to('#carRef_01', {
                x: 60,
                scrollTrigger: {
                    trigger: '#bodyRef',
                    start: "+=300px",
                    end: "bottom top",
                    scrub: 4,
                }
            });
            gsap.timeline().to('.wheelRef_01', {
                rotate: 120,
                scrollTrigger: {
                    trigger: '#bodyRef',
                    start: "+=300px",
                    end: "bottom top",
                    scrub: 4,
                }
            });
            gsap.timeline().to('#carRef_02', {
                x: -60,
                scrollTrigger: {
                    trigger: '#bodyRef',
                    start: "+=300px",
                    end: "bottom top",
                    scrub: 4,
                }
            });
            gsap.timeline().to('.wheelRef_02', {
                rotate: -120,
                scrollTrigger: {
                    trigger: '#bodyRef',
                    start: "+=300px",
                    end: "bottom top",
                    scrub: 4,
                }
            });
        }
    }, []);
    return (
        <section id="bodyRef" className={classes.bannerWrap}>
            <div className="container">
                <div className={classes.banner}>
                    <div className={classes.content}>
                        <h1 className={classes.title}>
                            <span id="titleText_01" className={[classes.titleText_01, 'font-80-caveat'].join(' ')}>
                                {t('home_banner_title_01')}
                            </span>
                            <span id="titleText_02" className={[classes.titleText_02, 'font-75'].join(' ')}>
                                {t('home_banner_title_02')}
                            </span>
                        </h1>
                        <p className={[classes.text, 'font-18'].join(' ')}>
                            {t('home_banner_text')}
                        </p>
                        <div className={classes.buttons}>
                            <Button setFormActive={setFormActive} setFormFrom={setFormFrom} formFrom={'Join as an Affiliate'}>
                                {t('home_banner_btn_join_aff')}
                            </Button>
                            <Button setFormActive={setFormActive} setFormFrom={setFormFrom} formFrom={'Join as a Broker'}>
                                {t('home_banner_btn_join_brok')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.images}>
                <div id="girlWrapRef_01" className={[classes.girlWrap_01, classes.girlWrap].join(' ')}>
                    <img id="girlRef_01" src={girl_01} alt=''/>
                </div>
                <div id="girlWrapRef_02" className={[classes.girlWrap_02, classes.girlWrap].join(' ')}>
                    <img id="girlRef_02" src={girl_02} alt=''/>
                </div>
                <div id="buildWrapRef_01" className={[classes.buildWrap_01, classes.buildWrap].join(' ')}>
                    <img id="buildRef_01" src={build_01} alt=''/>
                </div>
                <div id="buildWrapRef_02" className={[classes.buildWrap_02, classes.buildWrap].join(' ')}>
                    <img id="buildRef_02" src={build_02} alt=''/>
                </div>
                <div id="palmWrapRef_01" className={[classes.palmWrap_01, classes.palmWrap].join(' ')}>
                    <img id="palmRef_01" src={palm_01} alt=''/>
                </div>
                <div id="palmWrapRef_02" className={[classes.palmWrap_02, classes.palmWrap].join(' ')}>
                    <img id="palmRef_02" src={palm_02} alt=''/>
                </div>
                <div id="carWrapRef_01" className={[classes.carWrap_01, classes.carWrap].join(' ')}>
                    <div id="carRef_01">
                        <img src={car_01} alt=''/>
                        <div className={[classes.wheelWrap_01, classes.wheelWrap, 'wheelWrapRef_01', 'wheelWrapRef_01_01'].join(' ')}>
                            <img className="wheelRef_01" src={wheel} alt=''/>
                        </div>
                        <div className={[classes.wheelWrap_02, classes.wheelWrap, 'wheelWrapRef_01', 'wheelWrapRef_01_02'].join(' ')}>
                            <img className="wheelRef_01" src={wheel} alt=''/>
                        </div>
                    </div>
                </div>
                <div id="carWrapRef_02" className={[classes.carWrap_02, classes.carWrap].join(' ')}>
                    <div id="carRef_02">
                        <img src={car_02} alt=''/>
                        <div className={[classes.wheelWrap_01, classes.wheelWrap, 'wheelWrapRef_02', 'wheelWrapRef_02_01'].join(' ')}>
                            <img className="wheelRef_02" src={wheel} alt=''/>
                        </div>
                        <div className={[classes.wheelWrap_02, classes.wheelWrap, 'wheelWrapRef_02', 'wheelWrapRef_02_02'].join(' ')}>
                            <img className="wheelRef_02" src={wheel} alt=''/>
                        </div>
                    </div>
                </div>
                <img className={[classes.car_mob_01, classes.car_mob].join(' ')} src={car_mob_01} alt=''/>
                <img className={[classes.car_mob_02, classes.car_mob].join(' ')} src={car_mob_02} alt=''/>
            </div>
        </section>
    );
};

export default Banner;
