import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import classes from "./Header.module.scss";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import i18n from "i18next";
import { headerRoutes } from "../../../router";
import $ from "jquery";
import logo from "../../../assets/img/logo.png";

const languages = [
  { value: "en", label: "EN" },
  { value: "ua", label: "UA" },
];

const Header = () => {
  const { t } = useTranslation();
  const [burgerOpened, setBurgerOpened] = useState(false);
  const { pathname } = useLocation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const headerRef = useRef(null);

  const setLocale = (lang) => {
    i18n.changeLanguage(lang);
    navigate(`/${lang}/${_.last(pathname.split("/"))}`);
  };

  useEffect(() => {
    const langs = _.dropRight(i18n.options.supportedLngs);
    if (lang === undefined || !langs.includes(lang)) {
      setLocale("en");
    }
  }, [pathname]);

  const listenScroll = () => {
    if (window.pageYOffset > 70) {
      $(headerRef.current).removeClass(classes.hideBg);
    } else {
      $(headerRef.current).addClass(classes.hideBg);
    }
  };

  useEffect(() => {
    listenScroll();
  }, [burgerOpened]);
  // useEffect(() => {
  //   $(headerRef.current).removeClass(classes.lightBg);
  //   window.addEventListener("scroll", (event) => {
  //     listenScroll();
  //   });
  //   listenScroll();
  // }, [pathname]);
  useEffect(() => {
    setBurgerOpened(false);
  }, [pathname]);
  useEffect(() => {
    if (burgerOpened) {
      $("body").addClass("lock");
    } else {
      $("body").removeClass("lock");
    }
  }, [burgerOpened]);
  useEffect(() => {
    $(headerRef.current).removeClass(classes.lightBg);
    window.addEventListener("scroll", (event) => {
      listenScroll();
    });
    if ($("body").hasClass("lock")) {
      $("body").removeClass("lock");
    }
  }, []);
  window.addEventListener("resize", (event) => {
    setBurgerOpened(false);
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div
      ref={headerRef}
      className={`${classes.header} ${burgerOpened && classes.headerActive} ${
        classes.hideBg
      } no-select`}
    >
      <div className={["container", classes.container].join(" ")}>
        <div className={classes.headerRow}>
          <div className={classes.headerRowLogo}>
            <Link to={`/${lang}/`} onClick={() => setBurgerOpened(false)}>
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className={classes.headerRowRight}>
            <a href="mailto:offers@plm.media">
              <svg xmlns="http://www.w3.org/2000/svg" width="29" height="25" viewBox="0 0 29 25" fill="none"><g clipPath="url(#clip0_2283_6583)"><path d="M25.8929 23.2143H2.67857C1.19643 23.2143 0 22.0179 0 20.5358V4.46434C0 2.9822 1.19643 1.78577 2.67857 1.78577H25.8929C27.375 1.78577 28.5714 2.9822 28.5714 4.46434V20.5358C28.5714 22.0179 27.375 23.2143 25.8929 23.2143ZM2.67857 3.57148C2.17857 3.57148 1.78571 3.96434 1.78571 4.46434V20.5358C1.78571 21.0358 2.17857 21.4286 2.67857 21.4286H25.8929C26.3929 21.4286 26.7857 21.0358 26.7857 20.5358V4.46434C26.7857 3.96434 26.3929 3.57148 25.8929 3.57148H2.67857Z" fill="white"/><path d="M14.2857 15.9999C13.0357 15.9999 11.8928 15.4999 11.0357 14.5892L1.66068 4.62495C1.3214 4.26781 1.33925 3.69638 1.6964 3.35709C2.05354 3.01781 2.62497 3.03566 2.96425 3.39281L12.3393 13.3571C13.3571 14.4464 15.2143 14.4464 16.2321 13.3571L25.6071 3.41066C25.9464 3.05352 26.5178 3.03566 26.875 3.37495C27.2321 3.71424 27.25 4.28566 26.9107 4.64281L17.5357 14.6071C16.6785 15.5178 15.5357 16.0178 14.2857 16.0178V15.9999Z" fill="white"/></g><defs><clipPath id="clip0_2283_6583"><rect width="28.5714" height="25" fill="white"/></clipPath></defs></svg>
            </a>
            <div className={classes.langs}>
              <p 
                className={classes.lang}
                onClick={() => lang === 'en' ? setLocale('ua') : setLocale('en')}
              >
                {lang === 'en' ? 'UA' : 'EN'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
