import _ from 'lodash';
import React, { useEffect, useState } from "react";
import $ from 'jquery';
import axios from 'axios';
import classes from './Form.module.scss';
import { useTranslation, Trans } from "react-i18next";
import palm from '../../../assets/img/form_palm.png';
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

const types = [
    { value: 'broker', label: 'Broker', },
    { value: 'affiliate', label: 'Affiliate', },
];

const Form = ({ formActive, setFormActive, formFrom, setFormFrom }) => {
    const { t } = useTranslation();
    const [ success, setSuccess ] = useState(false);
    
    const [ name, setName ] = useState('');
    const [ contacts, setContacts ] = useState('');
    const [ company, setCompany ] = useState('');
    const [ vertical, setVertical ] = useState('');
    const [ type, setType ] = useState('');
    const [ check, setCheck ] = useState(false);

    const [ nameValid, setNameValid ] = useState(true);
    const [ contactsValid, setContactsValid ] = useState(true);
    const [ companyValid, setCompanyValid ] = useState(true);
    const [ verticalValid, setVerticalValid ] = useState(true);
    const [ typeValid, setTypeValid ] = useState(true);
    const [ checkValid, setCheckValid ] = useState(true);

    const setDataDefault = () => {
        setName('');
        setContacts('');
        setCompany('');
        setVertical('');
        setType('');
        setCheck(false);
        setNameValid(true);
        setContactsValid(true);
        setCompanyValid(true);
        setVerticalValid(true);
        setTypeValid(true);
        setCheckValid(true);
        setSuccess(false);
    };

    useEffect(() => {
        setDataDefault();
        if (formActive) {
            $('body').addClass("ovf-hidden");
        } else {
            $('body').removeClass("ovf-hidden");
        }
    }, [ formActive ]);

    useEffect(() => {
        if (formActive) {
            if (formFrom == 'Join as an Affiliate') {
                setType('Affiliate')
            }
            if (formFrom == 'Join as a Broker') {
                setType('Broker')
            }
        }
    }, [ formFrom, formActive ]);

    const validateString = (getter, setter) => {
        if (getter === '') {
            setter(false);
            return false;
        }
        setter(true);
        return true;
    };
    const validateCheck = () => {
        if (check === false) {
            setCheckValid(false);
            return false;
        }
        setCheckValid(true);
        return true;
    };

    const validateInputData = () => {
        let valid = true;
        const validArr = [];
        validArr.push(validateString(name, setNameValid));
        validArr.push(validateString(contacts, setContactsValid));
        validArr.push(validateString(company, setCompanyValid));
        validArr.push(validateString(vertical, setVerticalValid));
        validArr.push(validateString(type, setTypeValid));
        validArr.push(validateCheck());

        _.map(validArr, (item) => !item && (valid = false));

        return valid;
    };

    const sendMessage = async () => {
        const messageData = {
            name,
            contacts,
            company,
            vertical,
            type,
            formFrom: formFrom !== '' ? formFrom : 'undefined',
        };
        setDataDefault();
        try {
            const data = await axios.post('https://civbt.xyz/api/bot/yIcvpKLyih', messageData);
            if (data.status === 200) {
                setSuccess(true);
            } else {
                throw({ message: 'Something went wrong. Try again.' })
            }
        } catch (error) {
            alert(`${error.message}`)
        }
    };

    const submit = () => {
        if (validateInputData()) {
            sendMessage();
        }
        return;
    };

    return (
        <section className={`${classes.formWrap} ${formActive && classes.formWrapActive}`}>
            <div onClick={() => setFormActive(false)} className={classes.closeArea}></div>
            <div className={`container ${classes.formContainer}`}>
                <div className={`${classes.form} ${success && classes.formSuccess}`}>
                    <div onClick={() => setFormActive(false)} className={classes.close}></div>
                    <div className={classes.successPopup}>
                        <div className={classes.successPopupImg}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="23" viewBox="0 0 32 23" fill="none">
                                <path d="M11.625 22.8431L0.6875 11.9034L3.77844 8.8125L11.625 16.6569L28.2172 0.0625L31.3125 3.15781L11.625 22.8431Z" fill="#F83CC3"/>
                            </svg>
                        </div>
                        <p className={[classes.successPopupText, 'font-17'].join(' ')}>
                            <Trans>
                                {t('form_form_success')}
                            </Trans>
                        </p>
                    </div>
                    <div className={classes.formContent}>
                        <div className={classes.inputs}>
                            <div className={classes.row}>
                                <div className={`${classes.inputWrap} ${!nameValid && classes.borderRed}`}>
                                    <input
                                        className={classes.input}
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder={t('form_name')}
                                    />
                                </div>
                                <div className={`${classes.inputWrap} ${!contactsValid && classes.borderRed}`}>
                                    <input
                                        className={classes.input}
                                        type="text"
                                        value={contacts}
                                        onChange={(e) => setContacts(e.target.value)}
                                        placeholder={t('form_contacts')}
                                    />
                                </div>
                            </div>
                            <div className={`${classes.inputWrap} ${!companyValid && classes.borderRed}`}>
                                <input
                                    className={classes.input}
                                    type="text"
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                    placeholder={t('form_company')}
                                />
                            </div>
                            <div className={`${classes.inputWrap} ${!verticalValid && classes.borderRed}`}>
                                <input
                                    className={classes.input}
                                    type="text"
                                    value={vertical}
                                    onChange={(e) => setVertical(e.target.value)}
                                    placeholder={t('form_vertical')}
                                />
                            </div>
                            <div className={`${classes.inputWrap} ${!typeValid && classes.borderRed}`}>
                                <Dropdown
                                    options={types}
                                    onChange={(e) => setType(e.label)}
                                    value={type}
                                    placeholder="Broker / Aff"
                                />
                            </div>
                            <div className={`${classes.check} ${!checkValid && classes.checkRed}`}>
                                <div 
                                    className={`${classes.checkBox} ${check && classes.checkBoxActive} cursorHoverEl`} 
                                    onClick={() => setCheck((value) => !value)}
                                ></div>
                                <p className={classes.checkText}>
                                    <Trans components={{a: <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank"></a>}}>
                                        {t('form_check_text')}
                                    </Trans>
                                </p>
                            </div>
                        </div>
                        <div className={classes.btnWrap}>
                            <div onClick={submit} className={`${classes.button} cursorHoverEl`}>
                                <span>
                                    {t('form_btn_send')}
                                </span>
                            </div>
                        </div>
                    </div>
                    <img className={classes.palm} src={palm} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default Form;
